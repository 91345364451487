import React from "react";
import { useLoaderData } from "react-router-dom";

import { Box } from '@mui/material';

import ClubInfo from "../components/ClubInfo";

import { API_URL } from "../consts";

export async function loaderForID({ params }) {
  const club = await (await fetch(`${API_URL}/getID/${params.id}`)).json();
  return { club };
}

export async function loaderForURL({ params }) {
  const club = (await (await fetch(`${API_URL}/getByURL/${params.url}`)).json())[0];
  return { club };
}

export default function ClubInfoPage() {
  const { club } = useLoaderData();

  React.useEffect(() => {
    window.scroll(0, 0);

    return () => {
      window.scroll(0, 0);
    }
  }, []);

  return <Box sx={{
    minHeight: "93vh",
    width: "100vw",
    padding: "32px",
  }}>
    <ClubInfo club={club} isBigView={true} />
  </Box>
}