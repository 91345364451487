import React from "react";
import { Link } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function HomeClubCard({ club, setClubID, isSmallScreen }) {
  const theme = useTheme();

  const _item = <CardContent>
    <Typography variant="h5" gutterBottom sx={{
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}>
      {club.group_name}
    </Typography>
    {club.venue_address && <Typography>Meets: {club.day_of_week} {club.meetup_location && "at " + club.meetup_location} in <b>{club.city}</b>{club.starts_at && ", from " + club.starts_at} {club.ends_on && "to " + club.ends_on}</Typography>}
  </CardContent>;

  return <Card className="home_club_card" sx={{
    minWidth: 300,
    minHeight: 100,
    borderRadius: "16px",
  }}>
    {!isSmallScreen ? <Link to={`/${club.url_page}`} style={{
      textDecoration: "none",
      color: theme.palette.text.primary,
    }}>{_item}</Link> : <CardActionArea onClick={() => setClubID(club["club_id"])}>
      {_item}
    </CardActionArea>}
  </Card>
}