import { createTheme } from "@mui/material/styles";

const API_URL = "https://easttexaschess.com:5000";

const themeAddons = {
    typography: {
        fontFamily: [
            '"Questrial"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
}

const paletteAddons = {
    primary: {
        main: "#dddddd"
    }
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        ...paletteAddons,
    },
    ...themeAddons
});

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        ...paletteAddons,
    },
    ...themeAddons
});

export {darkTheme, lightTheme, API_URL};