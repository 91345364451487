import React from 'react';
import { Link, Outlet, useNavigation } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { darkTheme, lightTheme } from "../consts";
import { AppBar, Box, IconButton, Container, Fade, Slide, Toolbar, Typography, GlobalStyles } from "@mui/material";
import { DarkModeSwitch } from 'react-toggle-dark-mode';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChess } from "@fortawesome/free-solid-svg-icons";
import { faChessQueen } from "@fortawesome/free-solid-svg-icons";

export default function Root() {
  const [isDarkMode, setDarkMode] = React.useState(true);
  const [isLoaded, setLoaded] = React.useState(false);

  const navigation = useNavigation();


  React.useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => setLoaded(true), 1700);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => {
        window.removeEventListener('load', onPageLoad);
      };
    }
  }, []);

  React.useEffect(() => {
    setLoaded(navigation.state !== "loading")
  }, [navigation.state]);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <GlobalStyles styles={{
        '*': {
          '&::-webkit-scrollbar': {
            width: '6px'
          },
          '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(127,127,127,.4)',
          }
        }
      }} />
      <Fade in={!isLoaded || (navigation.state === "loading")} timeout={700} unmountOnExit mountOnEnter>
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            overflow: "none",
            position: "fixed",
            zIndex: "99",
            backgroundColor: "#000000",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{
            width: "80px",
            height: "80px",
            backgroundColor: "#ffffff",
          }}>
            <Box sx={{
              width: "40px",
              height: "40px",
              backgroundColor: "#555555"
              // #6A776A
            }}></Box>
            <Box sx={{
              width: "40px",
              height: "40px",
              marginLeft: "40px",
              backgroundColor: "#555555"
            }}></Box>
            <Box id="loading_chess_piece" sx={{
              width: "40px",
              height: "40px",
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transform: "translate(0, -80px)",
              color: "#000000",
              fontSize: "32px",
            }}>
              <FontAwesomeIcon icon={faChessQueen} />
              <Typography sx={{
                opacity: 0,
                fontFamily: "Questrial",
              }}>.</Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
      <Fade in={isLoaded} timeout={700}>
        <Slide direction="down" in={isLoaded}>
          <AppBar position="fixed" sx={{ zIndex: 99 }}>
            <Container maxWidth="xl">
              <Toolbar disableGutters sx={{
                transition: 'all 0.5s ease-in-out',
              }}>
                <IconButton component={Link} to="/">
                  <FontAwesomeIcon icon={faChess} />
                </IconButton>
                <Fade in={true} timeout={900}>
                  <Typography
                    variant="h6"
                    noWrap
                    component={Link}
                    to="/"
                    sx={{
                      mr: 2,
                      marginLeft: 2,
                      fontFamily: "Questrial",
                      display: 'flex',
                      fontWeight: 700,
                      letterSpacing: '.3rem',
                      color: 'inherit',
                      textDecoration: 'none',
                      flexGrow: 1,
                    }}>
                    EAST TEXAS CHESS
                  </Typography>
                </Fade>
                <DarkModeSwitch
                  checked={isDarkMode}
                  onChange={() => { setDarkMode(!isDarkMode) }}
                  size={24}
                />
              </Toolbar>
            </Container>
          </AppBar>
        </Slide>
      </Fade>
      <Fade in={isLoaded}>
        <div style={{
          padding: "12px",
        }}>
          <Box sx={{ height: "64px" }}></Box>
          <Outlet />
        </div>
      </Fade>
      <Box sx={{
        width: "100%",
        height: "22px",
        backgroundColor: "#575757",
        display: "flex",
        color: "black",
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        fontSize: 12,
      }}>
        <code>&copy; 2023 - Created by <a href="https://gladiusso.com" style={{ color: "#aaa" }}>V3ntus</a></code>
      </Box>
    </ThemeProvider>
  )
}