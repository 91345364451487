import React from "react";
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  Grow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import HomeClubCard from "../components/HomeClubCard";
import ClubInfo from "../components/ClubInfo";

import {API_URL} from "../consts";

export default function Home() {
  const theme = useTheme();

  const [clubID, setClubID] = React.useState(null);
  const [clubs, setClubs] = React.useState([]);
  const [clubsLoaded, setClubsLoaded] = React.useState(false);

  const smallScreen = useMediaQuery('(min-width: 900px) and (min-height: 600px)');

  React.useEffect(() => {
    fetch(`${API_URL}/getClubs`).then(res => res.json()).then((res) => {
      setClubs(res["clubs"]);
      setClubsLoaded(true);
    });

    return () => {
      setClubsLoaded(false);
    }
  }, []);

  return <>
    <Box sx={{
      width: "100%",
      // calculate full height minus the top bar minus the footer heights
      minHeight: 'calc(100vh - 64px - 58px)',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: (smallScreen ? "row" : "column"),
      textAlign: "center",
    }}>
      <Box
        sx={{
          height: "100%",
          width: { xs: "100vw", md: "50vw" },
          padding: "16px",
        }}
      >
        {clubsLoaded ? <Grid container spacing={1} columns={2} sx={{
          maxHeight: "80vh",
          overflowY: "scroll",
        }}>
          {clubs.map((club, idx) => {
            return <Grid item xs key={idx} sx={{
              paddingRight: "8px",
            }}>
              <Grow in={true} timeout={300 * idx}>
                <div>
                  <HomeClubCard club={club} setClubID={setClubID} isSmallScreen={smallScreen} />
                </div>
              </Grow>
            </Grid>
          })}
        </Grid> : <Box sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <CircularProgress />
        </Box>}
      </Box>
      {smallScreen ? <Box
        sx={{
          height: "100%",
          width: 'calc(50vw - 16px)',
          marginLeft: "16px",
          backgroundColor: theme.palette.action.hover,
          padding: "16px",
          textAlign: "left",
          borderRadius: "16px",
        }}
      >
        {clubID ? <ClubInfo club={clubs[parseInt(clubID)]}/> : <Fade in={true} timeout={700}>
          <div>
            <Grow in={true} timeout={700}><Typography variant="h3">East Texas Chess</Typography></Grow>
            <Grow in={true} timeout={900}><Typography variant="body"><i>Connect with active chess clubs in the East Texas area</i></Typography></Grow>
          </div>
        </Fade>}
      </Box> : <Box sx={{
        width: "80%",
      }}>
        <Grow in={true} timeout={700}><Typography variant="h3">East Texas Chess</Typography></Grow>
        <Grow in={true} timeout={900}><Typography variant="body"><i>Connect with active chess clubs in the East Texas area</i></Typography></Grow>
      </Box>}
    </Box>
  </>
}