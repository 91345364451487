import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import './index.css';

// Routes
import Root from "./routes/Root";
import Home from "./routes/Home";
import ClubInfoPage, {loaderForID as clubLoaderForID, loaderForURL as clubLoaderForURL} from "./routes/ClubInfoPage";
import ErrorPage from "./routes/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        index: true,
        element: <Home/>,
      },
      {
        path: "/:url",
        element: <ClubInfoPage/>,
        loader: clubLoaderForURL
      },
      {
        path: "club/:id",
        element: <ClubInfoPage/>,
        loader: clubLoaderForID
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
