import {Box, Typography} from '@mui/material';
import {Link, useRouteError} from "react-router-dom";


export default function ErrorPage(props) {
  const error = useRouteError();
  console.error(error);

  return <Box sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
  }}>
    <Typography variant="h1">Error</Typography>
    <Typography variant="h6">
      <i>{props.customError || error.statusText || error.message}</i>
    </Typography>
    <Link to="/">Go back to Home</Link>
  </Box>
}