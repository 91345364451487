import React, {useEffect} from "react";

import {ButtonGroup, Fade, Grid, IconButton, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram, faDiscord, faMeetup} from "@fortawesome/free-brands-svg-icons";
import ErrorPage from "../routes/ErrorPage";

function Row({label, value}) {
  if (typeof value == "string") {
    value = <Typography>{value}</Typography>
  }
  return <>
    <Grid item xs={6}>
      <div style={{
        color: "#AFAFAF"
      }}>
        {label}
      </div>
    </Grid>
    <Grid item xs={6} zeroMinWidth>
      {value}
    </Grid>
  </>
}

export default function ClubInfo(props) {
  const club = props.club;
  const isBigView = props.isBigView ?? false;
  const theme = useTheme();

  useEffect(() => {
    if (club.group_name) {
      document.title = club.group_name;
    }
  }, []);

  return club && typeof club === "object" && Object.entries(club).length > 0 ? <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      maxWidth: 600,
    }}>
      {club.banner && isBigView && <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px",
        marginTop: "-20px",
      }}>
        <img src={club.banner} alt="Club logo" style={{
          maxHeight: 300,
        }}/>
      </div>}
      <Fade in={true} timeout={1200}>
        <Typography component={Link} to={`/${club.url_page}`} variant="h1" sx={{
          maxWidth: 700,
          padding: "8px",
          fontSize: {xs: "4rem", md: "3rem"},
          textDecoration: "none",
          color: theme.palette.text.primary,
        }}>{club.group_name}</Typography>
      </Fade>
      {club.club_slogan && <Typography sx={{padding: "8px"}}><i>{club.club_slogan}</i></Typography>}
      {/* Location */}
      <div style={{
        fontSize: {xs: "14px", md: "24px"},
        paddingTop: "12px",
      }}>
        <Typography variant="h6">Location</Typography>
        <Grid container spacing={0.5}>
          {club.city && <Row label="City" value={club.city}/>}
          {club.meetup_location && <Row label="Meetup Location" value={club.meetup_location}/>}
          {club.venue_address && <Row label="Venue Address" value={<a href={club.directions}
                                                                      style={{color: "#AAAAFF"}}>{club.venue_address}</a>}/>}
        </Grid>
      </div>

      {/* Details */}
      <div style={{
        fontSize: {xs: "14px", md: "24px"},
        paddingTop: "12px",
      }}>
        <Typography variant="h6">Details</Typography>
        <Grid container spacing={0.5}>
          {club.attendance && <Row label="Attendance" value={club.attendance}/>}
          {club.day_of_week && <Row label="Meeting Days" value={club.day_of_week}/>}
          {club.starts_at && <Row label="Starts at" value={club.starts_at}/>}
          {club.ends_on && <Row label="Ends on" value={club.ends_on}/>}
          {club.status && <Row label="Meeting Status" value={club.status}/>}
          {club.founding_date && <Row label="Founding Date" value={club.founding_date}/>}
          {club.shuttered_date && <Row label="Shuttered Date" value={club.shuttered_date}/>}
        </Grid>
      </div>

      {/* Contact */}
      <div style={{
        fontSize: {xs: "14px", md: "24px"},
        paddingTop: "12px",
      }}>
        <Typography variant="h6">Contact</Typography>
        <Grid container spacing={0.5}>
          {club.director_name && <Row label="Director" value={club.director_name}/>}
          {club.director_phone && <Row label="Phone Number" value={club.director_phone}/>}
          {club.director_email && <Row label="Email"
                                       value={<a href={`mailto:${club.director_email}`} style={{color: "#AAAAFF"}}
                                                 className="grid_links">{club.director_email}</a>}/>}
          {club.alt_email && <Row label="Alternate Email"
                                  value={<a href={`mailto:${club.alt_email}`} style={{color: "#AAAAFF"}}
                                            className="grid_links">{club.alt_email}</a>}/>}
          {club.comments && <Row label="Comments" value={club.comments}/>}
        </Grid>
      </div>

      {/* Social */}
      <div style={{
        fontSize: {xs: "14px", md: "24px"},
        paddingTop: "12px",
      }}>
        <Typography variant="h6">Social</Typography>
        <Grid container spacing={2}>
          <Grid item xs={16}>
            <ButtonGroup size="large">
              {club.website && <IconButton onClick={() => {
                window.open(club.website, '_blank').focus()
              }}>
                <FontAwesomeIcon icon={faGlobe} style={{fontSize: 36}}/>
              </IconButton>}
              {club.facebook && <IconButton onClick={() => {
                window.open(club.facebook, '_blank').focus()
              }}>
                <FontAwesomeIcon icon={faFacebook} style={{fontSize: 36}}/>
              </IconButton>}
              {club.instagram && <IconButton onClick={() => {
                window.open(club.instagram, '_blank').focus()
              }}>
                <FontAwesomeIcon icon={faInstagram} style={{fontSize: 36}}/>
              </IconButton>}
              {club.discord && <IconButton onClick={() => {
                window.open(club.discord, '_blank').focus()
              }}>
                <FontAwesomeIcon icon={faDiscord} style={{fontSize: 36}}/>
              </IconButton>}
              {club.meetup_link && <IconButton onClick={() => {
                window.open(club.meetup_link, '_blank').focus()
              }}>
                <FontAwesomeIcon icon={faMeetup} style={{fontSize: 36}}/>
              </IconButton>}
            </ButtonGroup>
          </Grid>
          {club.chesscom_group && <Row label="Chess.com Group"
                                       value={<a href={club.chesscom_group} style={{color: "#AAAAFF"}}
                                                 className="grid_links">{club.chesscom_group}</a>}/>}
          {club.chesskids_group && <Row label="ChessKids Group"
                                        value={<a href={club.chesskids_group} style={{color: "#AAAAFF"}}
                                                  className="grid_links">{club.chesskids_group}</a>}/>}
          {club.lichess_group && <Row label="Lichess Group"
                                      value={<a href={club.lichess_group} style={{color: "#AAAAFF"}}
                                                className="grid_links">{club.lichess_group}</a>}/>}
        </Grid>
      </div>
    </div>
  </div> : <ErrorPage customError={"404 - Club not found"}/>;
}